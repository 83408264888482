import React from 'react';
import './Test.css';

function Test() {
    return (
        <div className="hero__col mod--2">
            <div
                style={{
                    opacity: 1,
                    transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    transformStyle: 'preserve-3d'
                }}
                className="hero__cards"
            >
                <div className="hero__card mod--1"></div>
                <div
                    style={{
                        transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                        transformStyle: 'preserve-3d'
                    }}
                    className="hero__card mod--2"
                >
                    <div className="hero__card-content">
                        <div className="hero__card-block">
                            <div className="hero__card-title">
                                <strong>Coshaco</strong>
                            </div>
                            <div className="hero__card-spec mod--1">
                                <strong>Android</strong>
                                <br />
                            </div>
                            <div className="hero__card-spec">
                                <strong>Developer</strong>
                            </div>
                        </div>
                        <div className="hero__card-block">
                            <div className="hero__card-p mod--bold">
                                <strong>Junior</strong>
                            </div>
                            <div className="hero__card-p mod--bold">
                                <strong>40,000€ p/a + avantages</strong>
                            </div>
                            <div className="hero__card-p mod--margin">Toulouse, France</div>
                            <a
                                data-lp-type="apply_now"
                                data-trigger="landing"
                                data-lp-place="1st_screen"
                                href="https://app.hirin.co/signup"
                                className="btn mod--arrow mod--hero-card w-button"
                            >
                                POSTULER MAINTENANT
                                <span className="btn__arrow mod--white mod--hero-card">&nbsp;</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="hero__stat-columns mod--mob"
                style={{
                    opacity: 1,
                    transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    transformStyle: 'preserve-3d'
                }}
            >
                <div className="hero__stat-col">
                    <div className="hero__stat-txt">companies</div>
                    <div data-stats="companies" className="hero__stat-numb">
                        981
                    </div>
                </div>
                <div className="hero__stat-col">
                    <div className="hero__stat-txt">candidates</div>
                    <div data-stats="talents" className="hero__stat-numb">
                        6874
                    </div>
                </div>
                <div className="hero__stat-col">
                    <div className="hero__stat-txt">jobs</div>
                    <div data-stats="companyPositions" className="hero__stat-numb">
                        1006
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Test;
