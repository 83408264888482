const { default: getWording } = require('wording');

exports.getJobURL = (job) => {
    const company_name = (job.external ? job.companyName : job.company.name)
        .toLowerCase()
        .replace('-', ' ')
        .replace(/\s+/g, ' ')
        .split(' ')
        .join('-');
    const job_title = job.title.toLowerCase().replace('-', ' ').replace(/\s+/g, ' ').split(' ').join('-');
    const location = getWording('cities', job.cityKey).toLowerCase().replace('-', ' ').replace(/\s+/g, ' ').split(' ').join('-');

    const url = `${job.id}_${company_name}_${job_title}_${location}`;

    return url.replace('/', '-');
};
