import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ExternalLink as ExternalLinkIcon } from '../../../icons/external-link';

export const HomeDesigners = (props) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: 'background.paper',
                py: 15
            }}
            {...props}
        >
            <Container maxWidth="lg">
                <Grid alignItems="center" container justifyContent="center" spacing={3}>
                    <Grid item md={6} sm={8} xs={12}>
                        <Box
                            sx={{
                                position: 'relative',
                                pt: 'calc(960 / 1225 * 100%)',
                                '& img': {
                                    height: 'auto',
                                    position: 'absolute',
                                    top: 0,
                                    width: '100%'
                                }
                            }}
                        >
                            <img alt="For designers" src={`/static/home/matching_${theme.palette.mode}.png`} />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant="h3">Algorithme de matching</Typography>
                        <Typography color="textSecondary" sx={{ my: 3 }} variant="subtitle1">
                            Nous avons développé un algorithme qui sélectionne les candidats correspondant aux offres d'emplois. Vous
                            n'aurez plus à rechercher des candidats en fonction de votre offre d'emploi. Notre algorithme le fait pour vous.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                m: -1
                            }}
                        >
                            <Button
                                component="a"
                                endIcon={<ExternalLinkIcon fontSize="small" />}
                                href="https://calendly.com/coshaco/30min"
                                size="large"
                                sx={{ m: 1 }}
                                target="_blank"
                                variant="outlined"
                            >
                                En savoir plus
                            </Button>
                            {/* <Button
                disabled
                endIcon={<ExternalLinkIcon fontSize="small" />}
                size="large"
                sx={{ m: 1 }}
                variant="outlined"
              >
                Preview Sketch
              </Button> */}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
