import api from './api';

const jobHandler = {
    addJob(job) {
        return api
            .post('/job', {
                job
            })
            .then((res) => {
                return res.data;
            });
    },
    editJob(job) {
        return api.patch(`/job`, job).then((res) => {
            return res.data;
        });
    },
    search(page, limit) {
        return api
            .post(`/job/search`, {
                page: page,
                limit: limit
            })
            .then((res) => {
                return res.data;
            });
    },
    getOne(id) {
        return api.get(`/job/${id}`).then((res) => {
            return res.data;
        });
    },
    deleteOne(id) {
        return api.delete(`/job/${id}`).then((res) => {
            return res.data;
        });
    }
};

export default jobHandler;
