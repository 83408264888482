import { Button } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';

const CGU = () => {
    return (
        <div style={{ margin: '0 25%' }}>
            <div>
                <h2>Conditions générales d'utilisation</h2>
                <ol>
                    <li>
                        <strong>Acceptation des conditions d'utilisation</strong>
                        <br />
                        En utilisant ce site internet, vous acceptez pleinement et sans réserve les présentes conditions générales
                        d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser ce site.
                    </li>
                    <li>
                        <strong>Description du service</strong>
                        <br />
                        Ce site internet est une plateforme de mise en relation entre les entreprises proposant des offres d'emploi et les
                        chercheurs d'emploi. Les utilisateurs peuvent créer des profils, renseigner leurs critères de recherche d'emploi, et
                        interagir avec les offres d'emploi qui correspondent à leurs critères.
                    </li>
                    <li>
                        <strong>Inscription et création de compte</strong>
                        <br />
                        Pour accéder aux fonctionnalités du site, il est obligatoire de s'inscrire et de créer un compte. Vous devez fournir
                        des informations exactes, complètes et à jour lors de votre inscription.
                    </li>
                    <li>
                        <strong>Collecte et utilisation des données personnelles</strong>
                        <br />
                        Nous collectons et utilisons vos données personnelles conformément à notre politique de confidentialité. En
                        utilisant ce site, vous consentez à la collecte, au stockage et à l'utilisation de vos informations conformément à
                        cette politique.
                    </li>
                    <li>
                        <strong>Utilisation appropriée du site</strong>
                        <br />
                        Il est interdit d'utiliser ce site pour publier du contenu illégal, offensant, diffamatoire, pornographique, ou
                        toute autre forme de contenu inapproprié. Vous ne devez pas non plus publier de liens vers des sites illégaux ou
                        offensants.
                    </li>
                    <li>
                        <strong>Propriété intellectuelle</strong>
                        <br />
                        Tous les contenus présents sur ce site, y compris les textes, les images, les vidéos, et les logos, sont protégés
                        par les lois sur la propriété intellectuelle. Vous vous engagez à ne pas copier, reproduire, ou distribuer ces
                        contenus sans autorisation préalable.
                    </li>
                    <li>
                        <strong>Limitation de responsabilité</strong>
                        <br />
                        Nous nous efforçons de fournir des informations exactes et à jour, mais nous ne pouvons garantir l'exactitude,
                        l'exhaustivité ou la fiabilité de ces informations. En utilisant ce site, vous reconnaissez que nous ne sommes pas
                        responsables des dommages directs ou indirects résultant de votre utilisation de ce site.
                    </li>
                    <li>
                        <strong>Modification des conditions d'utilisation</strong>
                        <br />
                        Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Les modifications seront
                        effectives dès leur publication sur le site. Il est de votre responsabilité de consulter régulièrement les
                        conditions d'utilisation pour être informé des éventuelles modifications.
                    </li>
                    <li>
                        <strong>Droit applicable et juridiction compétente</strong>
                        <br />
                        Les présentes conditions d'utilisation sont régies par le droit applicable dans votre pays de résidence. Tout litige
                        découlant de l'utilisation de ce site sera soumis à la juridiction compétente de votre pays.
                    </li>
                </ol>
            </div>

            <div>
                <h2>Utilisation des données personnelles</h2>
                <ol start="10">
                    <li>
                        <strong>Confidentialité des données</strong>
                        <br />
                        Nous nous engageons à protéger la confidentialité des données personnelles des utilisateurs de notre site. Les
                        données telles que l'adresse e-mail ne seront en aucun cas divulguées à des tiers sans le consentement explicite de
                        l'utilisateur, sauf si la loi l'exige.
                    </li>
                    <li>
                        <strong>Divulgation des informations aux entreprises</strong>
                        <br />
                        Lorsqu'un utilisateur exprime un intérêt pour une offre d'emploi, seules les informations pertinentes pour cette
                        offre seront transmises à l'entreprise concernée. Cela peut inclure le prénom de l'utilisateur ainsi que les
                        informations liées à son profil professionnel, telles que son expérience, ses compétences et ses qualifications.
                    </li>
                    <li>
                        <strong>Consentement de l'utilisateur</strong>
                        <br />
                        En utilisant notre plateforme, vous consentez à ce que les informations pertinentes de votre profil professionnel
                        soient partagées avec les entreprises proposant des offres d'emploi correspondant à vos critères de recherche. Votre
                        adresse e-mail et d'autres données personnelles ne seront partagées qu'avec votre consentement explicite.
                    </li>
                </ol>
            </div>

            <AnimateButton>
                <Button
                    onClick={() => {
                        window.history.back();
                    }}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ backgroundColor: '#383fc9' }}
                >
                    Retour
                </Button>
            </AnimateButton>
        </div>
    );
};

export default CGU;
