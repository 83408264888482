import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MENU, SET_USER } from 'store/actions';
import authHandler from 'api/auth';
import Customization from '../Customization';

// ==============================|| Onboarding LAYOUT ||============================== //

export default function OnboardingLayout() {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();

    useEffect(() => {
        authHandler
            .getMe()
            .then((res) => {
                dispatch({ type: SET_USER, user: res.data });
            })
            .catch(() => {
                window.location.href = '/signin';
            });

        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <>
            <Outlet />
            {/* <Customization /> */}
        </>
    );
}
