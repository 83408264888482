import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CheckCircleOutlined as CheckCircleOutlinedIcon } from '../../../icons/check-circle-outlined';
import Test from './Test';

export const HomeDevelopers = (props) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                py: 15
            }}
            {...props}
        >
            <Container maxWidth="lg">
                <Grid alignItems="center" container justifyContent="center" spacing={3}>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        sx={{
                            order: {
                                xs: 2,
                                md: 1
                            }
                        }}
                    >
                        <Grid container>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <Typography variant="h1" sx={{ fontSize: '56px' }}>
                                            Adieu les candidatures !
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{ my: 3 }} variant="h2">
                                            Laissez les meilleures entreprises tech vous faire une offre d'emploi
                                        </Typography>
                                    </Grid>
                                    {/* <Grid item>
                                        <Typography variant="h4">
                                            Créez votre profil, ajoutez votre salaire, vos préférences et répondez aux offres Oui ou Non.
                                            C'est aussi simple que cela.
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                <Grid container sx={{ display: 'flex' }} md={12}>
                                    <Box
                                        sx={{
                                            mt: 1,
                                            alignItems: {
                                                sm: 'center',
                                                xs: 'flex-start'
                                            },
                                            display: 'flex',
                                            flexDirection: {
                                                sm: 'row',
                                                xs: 'column'
                                            },
                                            py: 0,
                                            '& > *': {
                                                m: 1
                                            }
                                        }}
                                    >
                                        {['Tech', 'Product Management', 'Design'].map((item) => (
                                            <Box
                                                key={item}
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    m: 2
                                                }}
                                            >
                                                <CheckCircleOutlinedIcon color="success" sx={{ mr: 1 }} />
                                                <Typography variant="subtitle2">{item}</Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        sm={8}
                        xs={12}
                        sx={{
                            order: {
                                xs: 1,
                                md: 2
                            }
                        }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                pt: 'calc(960 / 1225 * 100%)',
                                '& img': {
                                    height: 'auto',
                                    position: 'absolute',
                                    top: 0,
                                    width: '100%'
                                }
                            }}
                        >
                            <Test />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
