import React, { useState } from 'react';
import AsyncSelect from 'react-select';

// const colourOptions = [
//   { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
//   { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
// ];

export default function MyAsyncSelect({ disabled, defaultData, data, onSelected, onUnSelected, isMulti, onInputChange, onClearAll }) {
    if (!defaultData) {
        defaultData = [];
    }

    if (disabled) {
        data = [];
    }

    const [selectedData, setSelectedData] = useState(defaultData);

    const contained = (values, s) => {
        const filtered = values.filter((v) => v.key === s.key);
        return filtered.length > 0;
    };

    return (
        <AsyncSelect
            onInputChange={onInputChange}
            onChange={(options) => {
                if (!Array.isArray(options)) {
                    onSelected({ key: options.value, name: options.label, id: options.id });
                    return;
                }
                const values = options.map((option) => {
                    return { key: option.value, name: option.label, id: option.id };
                });

                if (options.length === 0) {
                    onClearAll();
                    return;
                }

                if (values.length > selectedData.length) {
                    onSelected(values[options.length - 1]);
                } else {
                    onUnSelected(selectedData.find((s) => !contained(values, s)));
                }

                setSelectedData(values);

                // onChange(options.map((option) => { return { key: option.value, name: option.label } }))
            }}
            closeMenuOnSelect={!isMulti}
            defaultValue={defaultData.map((elem) => {
                return {
                    id: elem.id,
                    value: elem.key,
                    label: elem.name,
                    color: '#666666'
                };
            })}
            isMulti={isMulti}
            options={data.map((elem) => {
                return {
                    id: elem.id,
                    value: elem.key,
                    label: elem.name,
                    color: '#666666'
                };
            })}
        />
    );
}
