import api from './api';

const jobSkillHandler = {
    addSkill(jobId, skillId) {
        return api
            .post('/jobSkill', {
                skillId: skillId,
                jobId: jobId
            })
            .then((res) => {
                return res.data;
            });
    },
    deleteSkill(jobId, skill) {
        return api
            .delete(`/jobSkill/${skill.id}`, {
                data: { jobId: jobId }
            })
            .then((res) => {
                return res.data;
            });
    }
};

export default jobSkillHandler;
