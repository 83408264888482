import PropTypes from 'prop-types';

// material-ui
import { Box, Card, Grid } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import Filter from './Filter';
import { useEffect, useState } from 'react';
import jobHandler from 'api/job';
import InterviewRequest from 'views/pages/jobs/jobsPost/InterviewRequest/JobResume';

// ===============================|| SHADOW BOX ||=============================== //

const ShadowBox = ({ shadow }) => (
    <Card sx={{ mb: 3, boxShadow: shadow }}>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: 4.5,
                bgcolor: 'primary.light',
                color: 'grey.800'
            }}
        >
            <Box sx={{ color: 'inherit' }}>boxShadow: {shadow}</Box>
        </Box>
    </Card>
);

ShadowBox.propTypes = {
    shadow: PropTypes.string.isRequired
};

export default function Jobs() {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        jobHandler.search(1, 10).then((jobs) => {
            setJobs(jobs);
        });
    }, []);

    return (
        <MainCard title="Jobs">
            <Grid container spacing={gridSpacing} justifyContent="center" direction="column">
                <Grid item xs={12} lg={12} md={12} sm={12}>
                    <Grid container spacing={gridSpacing} justifyContent="center" alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InterviewRequest />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} sm={12} xs={12}></Grid>
                <Grid item md={12} sm={12} xs={12}>
                    {/* <JobDescription /> */}
                </Grid>
            </Grid>
        </MainCard>
    );
}
