import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import skillHandler from 'api/skill';
import MyAsyncSelect from '../../../ui-component/input/multiselect/MyAsyncSelect';
import candidateSkillHandler from 'api/candidateSkill';
import cities from '../../data/candidate/cities.json';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid, Switch, Typography } from '@mui/material';
import MySelect from '../../../ui-component/input/multiselect/MySelect';
import candidateHandler from 'api/candidate';
import workLevels from '../../data/candidate/workLevels.json';

export default function FormMentors({ user }) {
    const [open, setOpen] = React.useState(false);
    const [mentor, setMentor] = useState(false);

    const [candidate, setCandidate] = useState(user.candidate);
    const [skillIDs, setSkillIDs] = useState([]);
    const [mentorLevel, setMentorLevel] = useState(candidate.mentorLevel === '' ? workLevels[0].key : candidate.mentorLevel);
    const [jobTitle, setJobTitle] = useState(user.candidate.jobTitle === '' ? '' : user.candidate.jobTitle);

    useEffect(() => {
        setCandidate(user.candidate);
    }, [user]);

    useEffect(() => {
        if (!open) {
            return;
        }
        setSkillIDs(candidate ? candidate.mentorSkills.map((skill) => skill.id) : []);
        setMentor(candidate.mentor);
    }, [candidate, open]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [text, setText] = useState('');

    const [skills, setSkills] = useState([]);
    useEffect(() => {
        skillHandler.searchSkill(1, 20, text).then((skills) => {
            setSkills(skills);
        });
    }, [text]);

    const handleChange = (event) => {
        setCity(event.target.value);
    };
    const handleMentor = async (event) => {
        const value = mentor !== undefined ? mentor : user.candidate.mentor;
        setMentor(!value);
    };

    if (!candidate) {
        return <></>;
    }

    console.log('CACA user.candidate.mentorLevel === ""', user.candidate.mentorLevel === '');
    console.log('CACA workLevels[0].key', workLevels[0].key);
    console.log('CACA user.candidate.mentorLevel', user.candidate.mentorLevel);
    console.log('CACA', user.candidate.mentorLevel === '' ? workLevels[0].key : user.candidate.mentorLevel);

    console.log('CACA', mentorLevel);

    return (
        <div>
            {user.candidate.mentor ? (
                <Button variant="outlined" sx={{ m: 3 }} onClick={handleClickOpen}>
                    Paramètres Mentor
                </Button>
            ) : (
                <Button variant="outlined" sx={{ m: 3 }} onClick={handleClickOpen}>
                    Devenir Mentor
                </Button>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Devenir Mentor</DialogTitle>
                <DialogContent sx={{ m: 3, height: '500px' }}>
                    <DialogContentText>
                        Devenir mentor, c'est aider bénévolement des personnes moins expérimentées dans leur développement de compétences et
                        dans leur recherchde d'emploi.
                    </DialogContentText>
                    <Grid sx={{ mt: 3 }} item container alignItems="center">
                        <Grid item>
                            <Typography variant="subtitle1">Accepter d'être Mentor</Typography>
                        </Grid>
                        <Grid item>
                            <Switch checked={mentor} onChange={handleMentor} name="sdm" size="small" />
                        </Grid>
                    </Grid>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <Typography variant="subtitle1"></Typography>
                        <TextField
                            id="outlined-basic"
                            value={jobTitle}
                            onChange={(e) => {
                                setJobTitle(e.target.value);
                            }}
                            label="Poste actuel"
                            variant="outlined"
                            fullWidth
                        />
                    </FormControl>
                    <Typography variant="subtitle1" sx={{ mt: 3 }}>
                        Quelle est votre niveau d'expérience ?
                    </Typography>
                    <MySelect
                        onSelected={(level) => {
                            setMentorLevel(level.key);
                        }}
                        isMulti={false}
                        defaultData={[workLevels.find((level) => level.key === mentorLevel)]}
                        data={workLevels}
                    />
                    <Typography variant="subtitle1" sx={{ mt: 3 }}>
                        Quelles sont les compétences que vous maîtrisez le plus ?
                    </Typography>
                    <MyAsyncSelect
                        onInputChange={(value) => {
                            setText(value);
                        }}
                        onSelected={(skill) => {
                            setSkillIDs([...skillIDs, skill.id]);
                        }}
                        onUnSelected={(skill) => {
                            console.log('un selected', skill);
                            setSkillIDs(skillIDs.filter((skillId) => skillId !== skill.id));

                            // candidateSkillHandler.deleteMentorSkill(skill);
                        }}
                        onClearAll={(skills) => {
                            console.log('un clear All', skills);
                            setSkillIDs([]);
                        }}
                        isMulti={true}
                        defaultData={candidate.mentorSkills}
                        data={skills}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        Annuler
                    </Button>
                    <Button
                        onClick={() => {
                            candidateHandler.saveMentor(mentor, skillIDs, jobTitle, mentorLevel).then((candidate) => {
                                setCandidate(candidate);
                                handleClose();
                            });
                        }}
                        variant="contained"
                    >
                        Sauvegarder
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
