import { AppBar, Box, Button, Container, IconButton, Link, Toolbar } from '@mui/material';
import { Menu as MenuIcon } from '../../../icons/menu';
import { Logo } from './logo';

export const MainNavbar = (props) => {
    return (
        <AppBar
            elevation={0}
            sx={{
                backgroundColor: 'background.paper',
                borderBottomColor: 'divider',
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                color: 'text.secondary'
            }}
        >
            <Container maxWidth="lg">
                <Toolbar disableGutters sx={{ minHeight: 64 }}>
                    <a>
                        <Logo
                            sx={{
                                display: {
                                    md: 'inline',
                                    xs: 'none'
                                },
                                height: 40,
                                width: 40
                            }}
                        />
                    </a>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton
                        color="inherit"
                        sx={{
                            display: {
                                md: 'none'
                            }
                        }}
                    >
                        <MenuIcon fontSize="small" />
                    </IconButton>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: {
                                md: 'flex',
                                xs: 'none'
                            }
                        }}
                    >
                        <Button
                            component="a"
                            href="https://calendly.com/coshaco/30min"
                            size="medium"
                            sx={{ ml: 2 }}
                            target="_blank"
                            variant="outlined"
                        >
                            Nous rencontrer
                        </Button>
                        <Button component="a" href="https://calendly.com/coshaco/30min" size="medium" sx={{ ml: 2 }} variant="contained">
                            Demander une démo
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
