import { Divider } from '@mui/material';
import { HomeHero } from './home-hero';
import { HomeDevelopers } from './home-developers';
import { styled } from '@mui/material/styles';
import { HomeDesigners } from './home-designers';
import { HomeFeatures } from './home-features';
import { HomeTestimonials } from './home-testimonials';
import { HomeAdvice } from './home-advice';
import { MainNavbar } from './main-navbar';
import { Footer } from './footer';
import { Helmet } from 'react-helmet';
import { hotjar } from 'react-hotjar';
import { useEffect } from 'react';

const LandingRoot = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '100%',
    paddingTop: 64
}));

hotjar.initialize(3340991, 6);

// Identify the user
hotjar.identify('USER_ID', { userProperty: 'value' });

const Landing = () => {
    useEffect(() => {
        hotjar.event('landing');
    });

    return (
        // <LandingRoot>
        //     <MainNavbar />
        //     <title>Coshaco recruteur</title>
        //     <main>
        //         <HomeDevelopers />

        //         <HomeHero />
        //         <Divider />
        //         <Divider />
        //         <HomeDesigners />
        //         <HomeAdvice />
        //         <HomeFeatures />
        //         <HomeTestimonials />
        //         <Footer />
        //     </main>
        // </LandingRoot>
        <>
            <Helmet>
                <script src="https://embed.vev.page/v1/w5O9B_a5-2/pWGBA7iKp1O?fill"></script>
            </Helmet>
        </>
    );
};

export default Landing;
