import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';
import wsHandler from 'ws/ws';

// ==============================|| APP ||============================== //

import useWebSocket from 'react-use-websocket';

const WS_URL = 'ws://108.142.164.168:8081';

const App = () => {
    const customization = useSelector((state) => state.customization);

    // useEffect(() => {
    //     wsHandler.start();
    // }, []);

    useWebSocket(WS_URL, {
        onOpen: () => {
            console.log('CACA WebSocket connection established.');
        }
    });

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
