import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ActiveJobs from './ActiveJobs';
import InvactiveJobs from './InactiveJobs';

const useStyles = makeStyles({
    tab: {
        '& .MuiTabs-indicator': {
            backgroundColor: 'orange',
            height: 3
        },
        '& .MuiTab-root': {
            color: 'black',
            textTransform: 'none',
            fontSize: '18px'
        },
        '& .MuiTab-root.Mui-selected': {
            color: '#383ECA',
            textTransform: 'none'
        }
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

export default function TabsJobs({ jobs }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const classes = useStyles();

    return (
        <Box sx={{ maxWidth: { xs: 320, sm: 600, md: 1300 }, bgcolor: 'background.paper', MarginTop: '20px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="scrollable prevent tabs example"
                    className={classes.tab}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#383ECA'
                        }
                    }}
                >
                    <Tab label="Emplois en cours" />
                    <Tab label="Archivés" />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <ActiveJobs jobs={jobs} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <InvactiveJobs />
            </TabPanel>
        </Box>
    );
}
