// import useWebSocket from 'react-use-websocket';

// const WS_URL = 'ws://108.142.164.168:8081';

// const wsHandler = {
//     start() {
//         useWebSocket(WS_URL, {
//             onOpen: () => {
//                 console.log('WebSocket connection established.');
//             }
//         });
//     }
// };

// export default wsHandler;
