/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Jobs from 'views/Jobs/index';
import CreateJob from 'views/pages/jobs/CreateJob/Index';
import JobSuccess from 'views/pages/jobs/jobsPost/JobSuccess/Index';
import InterviewRequest from 'views/pages/jobs/jobsPost/InterviewRequest/JobResume';
import Mentors from 'views/pages/Mentors/Mentors';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/home')));
const Chat = Loadable(lazy(() => import('views/pages/Chat/Chat')));

// page routing
const UserProfile = Loadable(lazy(() => import('views/pages/settings/user')));
const Company = Loadable(lazy(() => import('views/pages/settings/company')));
const JobsPost = Loadable(lazy(() => import('views/pages/jobs/jobsPost')));
const Calendar = Loadable(lazy(() => import('views/pages/Calendar/Calendar')));
const Onboarding = Loadable(lazy(() => import('views/pages/Onboarding/Onboarding')));
const CompanyProfile = Loadable(lazy(() => import('views/pages/Profile/UserProfile/CompanyProfile')));
const UserProfileResume = Loadable(lazy(() => import('views/pages/Profile/UserProfile/UserProfileResume')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/home',
            element: <DashboardDefault />
        },
        {
            path: '/onboarding/edit',
            element: <Onboarding />
        },
        {
            path: '/user/social-profile/posts',
            element: <UserProfile />
        },
        {
            path: '/settings',
            children: [
                {
                    path: 'company',
                    element: <CompanyProfile />
                },
                {
                    path: 'managePeople',
                    element: <Company />
                }
            ]
        },
        {
            path: '/profile',
            children: [
                {
                    path: 'resume',
                    element: <UserProfileResume />
                }
            ]
        },
        {
            path: '/chat',
            element: <Chat />
        },
        {
            path: '/chat/:id',
            element: <Chat />
        },
        {
            path: '/calendar',
            element: <Calendar />
        },
        {
            path: '/mentors',
            element: <Mentors />
        },
        {
            path: '/job',
            children: [
                {
                    path: 'jobsPost',
                    element: <JobsPost />
                },
                {
                    path: 'jobsSearch',
                    element: <Jobs />
                },
                // {
                //     path: ':id',
                //     element: <JobsPost />
                // },
                {
                    path: 'success',
                    element: <JobSuccess />
                },
                {
                    path: 'interviews',
                    element: <InterviewRequest />
                }
            ]
        },

        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
