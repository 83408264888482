import React, { useState } from 'react';
import Typography from '@mui/material/Typography';

// material-ui
import { Avatar, Box, Button, Card, CardHeader, Chip, Divider, Grid, Input } from '@mui/material';
import FmdGoodIcon from '@mui/icons-material/FmdGood';

// project imports
import userHandler from 'api/user';
import FormMentors from './formMentors';
import FormAskMentors from './FormAskMentors';
import cities from '../../data/candidate/cities.json';

const getCityName = (city_key) => {
    for (const city of cities) {
        if (city.key === city_key) {
            return city.name;
        }
    }
    return '';
};

function MentorView({ user }) {
    const [mentorMatch, setMentorMatch] = useState(user.mentorMatch);
    const cityName = getCityName(user.candidate.mentorCity);

    return (
        <Grid item sm={3} xs={12}>
            <Box
                sx={{
                    borderColor: 'white',
                    borderRadius: 3,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    px: 3,
                    py: 4
                }}
            >
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <Avatar src={user.image}></Avatar>
                    <Box sx={{ ml: 2 }}>
                        <Typography variant="body2">{user.firstName}</Typography>
                        <Typography color="textSecondary" variant="subtitle2">
                            {user.job}
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                        <Chip label="disponible" color="primary">
                            Disponible
                        </Chip>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    {cityName.length > 0 && (
                        <Box sx={{ mr: 2, display: 'flex', flexDirection: 'row' }}>
                            <FmdGoodIcon />
                            <Typography color="textSecondary" variant="body2">
                                {cityName}
                            </Typography>
                        </Box>
                    )}

                    {/* <Box>
                        <Typography color="textSecondary" variant="body2">
                            3 ans d'expérience
                        </Typography>
                    </Box> */}
                </Box>
                <Box
                    sx={{
                        m: -1,
                        mt: 1
                    }}
                >
                    {(user.candidate.mentorSkills.slice(0, 4) || []).map((skill) => (
                        <Chip key={skill.id} label={skill.name} sx={{ m: 1 }} />
                    ))}
                </Box>
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    {!mentorMatch && <FormAskMentors user={user} setMentorMatch={setMentorMatch} />}
                    {mentorMatch && !mentorMatch.accepted && (
                        <Button variant="outlined" onClick={() => {}}>
                            Demande envoyée
                        </Button>
                    )}
                </Box>
            </Box>
        </Grid>
    );
}

export default MentorView;
