import api from './api';

const userHandler = {
    finishOnboarding() {
        return api.post('/user/finishOnboarding').then((res) => {
            return res.data;
        });
    },
    updateUserInfo(firstName, lastName, image, twitter, linkedin, youtube, github, twitch, tiktok, instagram, description, location) {
        return api
            .patch('/user/info', {
                firstName: firstName,
                lastName: lastName,
                image: image,
                twitter: twitter,
                linkedin: linkedin,
                youtube: youtube,
                github: github,
                twitch: twitch,
                tiktok: tiktok,
                instagram: instagram,
                description: description,
                location: location
            })
            .then((res) => {
                return res.data;
            });
    },
    addImage(file) {
        let data = new FormData();
        data.append('image', file, file.name);
        return api
            .post('/user/image', data, {
                headers: {
                    accept: 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`
                }
            })
            .then((res) => {
                return res.data;
            });
    },
    searchMentor(text, page, limit) {
        return api
            .post('/user/searchMentor', {
                page: page,
                text: text,
                limit: limit
            })
            .then((res) => {
                return res.data;
            });
    },
    addMentorMatch(mentorId, text) {
        return api
            .post('/user/mentor', {
                mentorId: mentorId,
                text: text
            })
            .then((res) => {
                return res.data;
            });
    },
    searchMentorMatch() {
        return api.post('/user/mentor/searchMatch').then((res) => {
            return res.data;
        });
    },
    accepthMentorMatch(userId) {
        return api.post('/user/mentor/accept', { userId: userId }).then((res) => {
            return res.data;
        });
    },
    refusehMentorMatch(userId) {
        return api.post('/user/mentor/refuse', { userId: userId }).then((res) => {
            return res.data;
        });
    },
    deleteAccount() {
        return api.post('/user/delete').then((res) => {
            return res.data;
        });
    }
};

export default userHandler;
