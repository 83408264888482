import React, { useEffect, useState } from 'react';

import Select from 'react-select';

import makeAnimated from 'react-select/animated';
import { colourOptions } from '../Skills/Data';
import skillHandler from 'api/skill';
import MyAsyncSelect from 'ui-component/select/MySelect';
import jobSkillHandler from 'api/jobSkill';

const animatedComponents = makeAnimated();

export default function SelectSKills({ job, setJob }) {
    const [text, setText] = useState('');
    const [skills, setSkills] = useState([]);

    useEffect(() => {
        skillHandler.searchSkill(1, 20, text).then((skills) => {
            setSkills(skills);
        });
    }, [text]);

    if (!job.id) {
        return <div></div>;
    }

    return (
        <MyAsyncSelect
            onInputChange={(value) => {
                setText(value);
            }}
            onSelected={(skill) => {
                setJob({ ...job, skills: [...job.skills, skill] });
                jobSkillHandler.addSkill(job.id, skill.id).then((s) => {});
            }}
            onUnSelected={(skill) => {
                setJob({ ...job, skills: job.skills.filter((s) => s.key !== skill.key) });
                jobSkillHandler.deleteSkill(job.id, skill);
            }}
            isMulti={true}
            defaultData={job.skills}
            data={skills}
        />
    );
}
