import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled, alpha } from '@mui/material/styles';

// material-ui
import { Avatar, Box, Button, Card, CardHeader, Chip, Divider, Grid, Input } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import AppBar from '@mui/material/AppBar';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import DoneIcon from '@mui/icons-material/Done';
import { useSelector } from 'react-redux';

// project imports
import userHandler from 'api/user';
import { gridSpacing } from 'store/constant';
import MentorMatch from './MentorMatch';
import Mentor from './MentorView';
import FormMentors from './formMentors';

function Mentors() {
    const limit = 10;
    const [page, setPage] = useState(1);
    const [text, setText] = useState('');
    const [users, setUsers] = useState([]);

    const user = useSelector((state) => {
        return state.customization.user;
    });

    useEffect(() => {
        userHandler.searchMentor(text, page, limit).then((mentors) => {
            setUsers(mentors);
        });
    }, []);

    return (
        <Card>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <CardHeader title="Mentors" />

                {user && <FormMentors user={user} />}
            </Box>
            <Divider />
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    px: 3,
                    py: 2
                }}
            >
                <SearchIcon fontSize="small" />
                <Box sx={{ ml: 2 }}>
                    <Input
                        disableUnderline
                        // onChange={(event) => setSearch(event.target.value)}
                        placeholder="Search connections"
                        // value={search}
                    />
                </Box>
            </Box>
            <Divider />

            <Box sx={{ m: 3 }}>
                <Grid container spacing={3}>
                    {users.map((user) => (
                        <Mentor key={user.id} user={user} />
                    ))}
                </Grid>
            </Box>
        </Card>
    );
}

export default Mentors;
