import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import OnboardingLayout from 'layout/OnboardingLayout';

const Onboarding = Loadable(lazy(() => import('views/pages/Onboarding/Onboarding')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const OnboardingRoutes = {
    path: '/',
    element: <OnboardingLayout />,
    children: [
        {
            path: '/onboarding',
            element: <Onboarding />
        }
    ]
};

export default OnboardingRoutes;
