import { useState } from 'react';
import { Avatar, Box, Container, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { MinusOutlined as MinusOutlinedIcon } from '../../../icons/minus-outlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ChatIcon from '@mui/icons-material/Chat';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Diversity3Icon from '@mui/icons-material/Diversity3';

const getFeatures = (theme) => [
    {
        icon: AccountTreeIcon,
        image: `/static/home/matching-feature_${theme}.png`,
        items: [
            '1. Matching',
            '2. Sélection des candidats ',
            '3. Entretiens',
            '4. Rencontre avec l équipe',
            '5. Recrutement',
            '6. Onboarding'
        ],
        subheader:
            'Notre algorithme sélectionne les meilleurs profils correspondant à votre offre. Obtenez des candidats qualifiés dès la publication de votre offre. ',
        title: 'Système de matching'
    },
    {
        icon: IntegrationInstructionsIcon,
        image: `/static/home/integration-ats_${theme}.png`,
        items: ['Greenhouse', 'SmartRecruiters', 'Workable', 'Lever', 'Recruitee', 'Flatch'],
        subheader: 'Gérer vos candidats avec notre ATS ou le vôtre',
        title: 'ATS et intégrations'
    },
    {
        icon: ChatIcon,
        items: ['Chat'],
        subheader: 'Interaction avec les candidats grâce à notre chat unique',
        image: `/static/home/chat_${theme}.png`,
        title: 'Chat'
    },
    {
        icon: CalendarMonthIcon,
        image: `/static/home/calendar_${theme}.png`,
        items: ['Calendrier'],
        subheader: 'Visibilité sur la disponibilité des candidats pour planifier des entretiens rapidement',
        title: 'Calendrier'
    },
    {
        icon: Diversity3Icon,
        image: `/static/home/collaboration_${theme}.png`,
        items: [
            'Evaluation de votre offre',
            'Accompagnement sur le sourcing',
            'Intégration dans l équipe',
            'Amélioration de la marque employeur'
        ],
        subheader: 'Nous vous accompagnons de l élaboration de votre offre à la prise de poste du candidat',
        title: 'Accompagnement personnalisé'
    }
];

export const HomeFeatures = (props) => {
    const theme = useTheme();
    const [selectedFeature, setSelectedFeature] = useState(0);

    const features = getFeatures(theme.palette.mode);

    const handleChangeFeature = (index) => {
        setSelectedFeature(index);
    };

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                py: 15
            }}
            {...props}
        >
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <Typography variant="h3">Recrutez et gérez votre recrutement</Typography>
                        <Typography color="textSecondary" sx={{ py: 2 }} variant="subtitle1">
                            Plateforme de recrutement développée pour optimiser et simplifier le recrutement de profils tech dans vos
                            équipes.
                        </Typography>
                        {features.map((feature, index) => {
                            const { icon: Icon, items, subheader, title } = feature;

                            const selected = index === selectedFeature;

                            return (
                                <Box
                                    key={title}
                                    onClick={() => handleChangeFeature(index)}
                                    sx={{
                                        borderRadius: 1,
                                        cursor: 'pointer',
                                        display: 'flex',
                                        mb: 2,
                                        p: 2,
                                        ...(selected && {
                                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                                            cursor: 'default'
                                        })
                                    }}
                                >
                                    <Avatar
                                        sx={{
                                            mr: 2,
                                            ...(selected && {
                                                backgroundColor: 'primary.main',
                                                color: 'primary.contrastText'
                                            })
                                        }}
                                    >
                                        <Icon fontSize="small" />
                                    </Avatar>
                                    <div>
                                        <Typography variant="h6">{title}</Typography>
                                        <Typography color="textSecondary" variant="body2">
                                            {subheader}
                                        </Typography>
                                        {selected && (
                                            <List
                                                disablePadding
                                                sx={{
                                                    display: 'grid',
                                                    gap: 1,
                                                    pt: 2,
                                                    ...(items.length > 4 && {
                                                        sm: 'repeat(2, 1fr)'
                                                    })
                                                }}
                                            >
                                                {items.map((item) => (
                                                    <ListItem disableGutters key={item} sx={{ py: 0 }}>
                                                        <ListItemAvatar
                                                            sx={{
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                minWidth: 0,
                                                                mr: 0.5
                                                            }}
                                                        >
                                                            <MinusOutlinedIcon color="primary" />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={<Typography variant="subtitle2">{item}</Typography>} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        )}
                                    </div>
                                </Box>
                            );
                        })}
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box
                            sx={{
                                position: 'relative',
                                pt: 'calc(965 / 1224 * 100%)',
                                '& img': {
                                    height: 'auto',
                                    position: 'absolute',
                                    top: 0,
                                    width: '100%'
                                }
                            }}
                        >
                            <img alt="" src={features[selectedFeature].image} />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
