import PropTypes from 'prop-types';

// material-ui
import { Box, Card, Grid } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import TabsJobs from './TabsJobs';

// ===============================|| SHADOW BOX ||=============================== //

const ShadowBox = ({ shadow }) => (
    <Card sx={{ mb: 3, boxShadow: shadow }}>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: 4.5,
                bgcolor: 'primary.light',
                color: 'grey.800'
            }}
        >
            <Box sx={{ color: 'inherit' }}>boxShadow: {shadow}</Box>
        </Box>
    </Card>
);

ShadowBox.propTypes = {
    shadow: PropTypes.string.isRequired
};

export default function Filter({ jobs }) {
    return (
        <Grid container spacing={gridSpacing} direction="row">
            <Grid item xs={12} lg={12} md={12} sm={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TabsJobs jobs={jobs} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}></Grid>
            <Grid item md={12} sm={12} xs={12}>
                {/* <JobDescription /> */}
            </Grid>
        </Grid>
    );
}
