import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Landing from 'views/pages/Landing/Landing';
import CGU from 'views/pages/cgu/cgu';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Linkedin = Loadable(lazy(() => import('views/pages/authentication/linkedin/Linkedin')));
const JobsPost = Loadable(lazy(() => import('views/pages/jobs/jobsPost')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <Landing />
        },
        {
            path: '/cgu',
            element: <CGU />
        },
        {
            path: '/signin',
            element: <AuthLogin3 />
        },
        {
            path: '/signup',
            element: <AuthRegister3 />
        },
        {
            path: '/linkedin',
            element: <Linkedin />
        },
        {
            path: '/job',
            children: [
                {
                    path: ':id',
                    element: <JobsPost />
                }
            ]
        }
    ]
};

export default AuthenticationRoutes;
