import React from 'react';

// material-ui
import { Button, Grid, Typography } from '@mui/material';

import { gridSpacing } from 'store/constant';

function JobSuccess() {
    return (
        <Grid
            container
            spacing={gridSpacing}
            justifyContent="center"
            direction="column"
            alignContent="center"
            sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}
        >
            <Grid item xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                <Grid container spacing={gridSpacing} justifyContent="center" alignItems="center" alignContent="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h1">Parfait !</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-circle-check"
                            width="100"
                            height="100"
                            viewBox="0 0 24 24"
                            stroke-width="3"
                            stroke="#00b341"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="12" r="9" />
                            <path d="M9 12l2 2l4 -4" />
                        </svg>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography> Votre offre d'emploi est en cours de validation</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography>Nous avons bien pris en compte votre demande de publication pour cette offre d'emploi.</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography>
                            Dans un délais maximum de 5 jours vous pourrez visualiser les candidats qui correspondent à cette offre
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button variant="contained">Super ! Merci</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}></Grid>
        </Grid>
    );
}

export default JobSuccess;
