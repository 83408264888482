import api from './api';

const skillHandler = {
    addSkill(key) {
        return api
            .post('/skill', {
                key: key
            })
            .then((res) => {
                return res.data;
            });
    },
    deleteSkill(job) {
        return api.delete(`/skill/${job.id}`).then((res) => {
            return res.data;
        });
    },
    searchSkill(page, limit, text) {
        return api
            .post(`/skill/search`, {
                page: page,
                limit: limit,
                text: text
            })
            .then((res) => {
                return res.data;
            });
    }
};

export default skillHandler;
