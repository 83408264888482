import api from './api';
import Cookies from 'universal-cookie';

const authHandler = {
    getMe() {
        return api.get('/user/me');
    },

    signup(firstName, lastName, email, phone, password, recruiter) {
        return api
            .post('/auth/signup', {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                password: password,
                recruiter: recruiter
            })
            .then((res) => {
                const token = res.data.token;
                const cookies = new Cookies();
                cookies.set('token', token, { path: '/' });

                return res.data;
            });
    },

    signin(email, password) {
        return api
            .post('/auth/signin', {
                email: email,
                password: password
            })
            .then((res) => {
                const token = res.data.token;
                const cookies = new Cookies();
                cookies.set('token', token, { path: '/' });

                return res.data;
            });
    },
    linkedin(code) {
        return api
            .post('/auth/linkedin', {
                code: code
            })
            .then((res) => {
                const token = res.data.token;
                const cookies = new Cookies();
                cookies.set('token', token, { path: '/' });

                return res.data;
            });
    }
};

export default authHandler;
