import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { Box, Button, Card, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// project imports
import { gridSpacing } from 'store/constant';
import TabsJobs from './TabsJobs';
import JobCard from '../../ui-component/cards/jobCard/JobCard';
import jobHandler from 'api/job';
import { getJobURL } from 'utils/getJobURL';
// ===============================|| SHADOW BOX ||=============================== //

const ShadowBox = ({ shadow }) => (
    <Card sx={{ mb: 3, boxShadow: shadow }}>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: 4.5,
                bgcolor: 'primary.light',
                color: 'grey.800'
            }}
        >
            <Box sx={{ color: 'inherit' }}>boxShadow: {shadow}</Box>
        </Box>
    </Card>
);

ShadowBox.propTypes = {
    shadow: PropTypes.string.isRequired
};

export default function ActiveJobs({ jobs }) {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <Grid container spacing={gridSpacing} direction="column">
            <Grid item xs={12} lg={12} md={12} sm={12}>
                <Grid container spacing={gridSpacing} direction="row" alignItems="center" sx={{ marginBottom: '40px', marginTop: '5px' }}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Typography variant="h3" component="h2">
                            Emplois en cours
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Grid container spacing={gridSpacing} direction="row" alignItems="center">
                            <Grid item>
                                <Typography variant="body1" component="h2">
                                    Trier par:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={age}
                                    onChange={handleChange}
                                    label="Age"
                                    sx={{ width: '150px', height: '40px' }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Grid container spacing={gridSpacing} direction="row" alignItems="center">
                            <Grid item>
                                <Typography variant="body1" component="h2">
                                    Status :
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={age}
                                    onChange={handleChange}
                                    label="Age"
                                    sx={{ width: '150px', height: '40px' }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                jobHandler.addJob({}).then((job) => {
                                    window.location = `/job/${getJobURL(job.id)}`;
                                });
                            }}
                        >
                            Ajouter
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}>
                    {jobs.map((job) => {
                        return (
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <JobCard job={job} />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}
