import { Avatar, Box, Button, Container, Typography } from '@mui/material';
import DevicesIcon from '@mui/icons-material/Devices';

export const HomeTestimonials = (props) => (
    <Box
        sx={{
            backgroundColor: '#7482eb',
            py: 15
        }}
        {...props}
    >
        <Container
            maxWidth="md"
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <DevicesIcon sx={{ fontSize: '50px', color: 'white' }} />
            <Typography align="center" color="primary.contrastText" variant="h3">
                Rencontrons-nous
            </Typography>
            <Typography
                align="center"
                color="primary.contrastText"
                variant="h5"
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mt: 2
                }}
            >
                Nous vous accompagnerons sur votre stratégie de recrutement pour trouver les meilleurs talents tech pour développer votre
                équipe.
            </Typography>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mt: 3
                }}
            >
                <Button href="https://calendly.com/coshaco/30min" variant="contained" sx={{ color: '#383fc9', backgroundColor: 'white' }}>
                    Demander une démo
                </Button>
            </Box>
        </Container>
    </Box>
);
