import wording from './wording.json';
import cities from './values/cities.json';
import companySizes from './values/companySizes.json';
import jobCategories from './values/jobCategories.json';
import jobPreferences from './values/jobPreferences.json';
import jobs from './values/jobs.json';
import workLevels from './values/workLevels.json';
import workTypes from './values/workTypes.json';

wording.values.cities = cities;
wording.values.companySizes = companySizes;
wording.values.jobCategories = jobCategories;
wording.values.jobPreferences = jobPreferences;
wording.values.jobs = jobs;
wording.values.workLevels = workLevels;
wording.values.workTypes = workTypes;

const lang = 'fr';

export default function getWording(category, key) {
    const w = wording.values[category][key];
    if (w) {
        const value = w[lang];
        if (value) {
            return value;
        }
        const defaultValue = w[wording.config.default];
        if (defaultValue) {
            return defaultValue;
        }
    }
    console.log(category, key);
    return 'KEY_NOT_FOUND';
}
