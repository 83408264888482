import React, { useEffect, useState } from 'react';
import './JobResume.css';
import Typography from '@mui/material/Typography';

// material-ui
import { Box, Card, CardHeader, Divider, Grid } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';

// project imports

import { gridSpacing } from 'store/constant';
import InterviewCard from '../CardInterview/InterviewCard';
import candidateHandler from 'api/candidate';

function InterviewRequest() {
    const [selectedMatches, setSelectedMatches] = useState([]);
    const [matches, setMatches] = useState([]);

    useEffect(() => {
        candidateHandler.searchMatch().then((matches) => {
            setSelectedMatches(matches.filter((match) => match.status === 'selected'));
            setMatches(matches.filter((match) => match.status !== 'selected'));
        });
    }, []);

    return (
        <Card>
            <CardHeader title="Demande d'entretien" />
            <Divider />
            <Box sx={{ m: 3 }}>
                <Grid container spacing={gridSpacing}>
                    {selectedMatches.map((match) => {
                        return (
                            <Grid key={match.id} item xs={12} sm={6} md={4} lg={4} xl={3}>
                                <InterviewCard match={match} />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
            <CardHeader title="Entretiens en cours" />
            <Divider />
            <Box sx={{ m: 3 }}>
                <Grid container spacing={gridSpacing}>
                    {matches.map((match) => {
                        return (
                            <Grid key={match.id} item xs={12} sm={6} md={4} lg={4} xl={3}>
                                <InterviewCard match={match} />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Card>
        // <Grid container spacing={gridSpacing} justifyContent="center" direction="column">
        //     <Grid item xs={12}>
        //         <Grid container spacing={gridSpacing}>
        //             <Grid item lg={12} md={12} sm={12} xs={12}>
        //                 <Grid container spacing={gridSpacing} direction="column">
        //                     <Grid item lg={12} md={12} sm={12} xs={12}>
        //                         <Typography variant="h3">Demande d'entretien</Typography>
        //                     </Grid>
        //                     <Grid
        //                         item
        //                         lg={12}
        //                         md={12}
        //                         sm={12}
        //                         xs={12}
        //                         direction="row"
        //                         sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gridGap: '20px' }}
        //                     >
        //                         <InterviewCard />
        //                     </Grid>
        //                 </Grid>
        //             </Grid>
        //         </Grid>
        //     </Grid>
        //     <Grid item xs={12}>
        //         <Grid container spacing={gridSpacing}>
        //             <Grid item lg={12} md={12} sm={12} xs={12}>
        //                 <Grid container spacing={gridSpacing} direction="column">
        //                     <Grid item lg={12} md={12} sm={12} xs={12}>
        //                         <Typography variant="h3">Entretien en cours</Typography>
        //                     </Grid>
        //                     <Grid
        //                         item
        //                         lg={12}
        //                         md={12}
        //                         sm={12}
        //                         xs={12}
        //                         direction="row"
        //                         sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gridGap: '20px' }}
        //                     >
        //                         <InterviewCard />
        //                         <InterviewCard />
        //                     </Grid>
        //                 </Grid>
        //             </Grid>
        //         </Grid>
        //     </Grid>
        // </Grid>
    );
}

export default InterviewRequest;
