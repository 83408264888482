import api from './api';

const chatHandler = {
    searchMentorChat(limit, page) {
        return api
            .post(`/chat/mentor/search`, {
                limit: limit,
                page: page
            })
            .then((res) => {
                return res.data;
            });
    },
    searchMessage(chatId, limit, page) {
        return api
            .post(`/chat/message/search`, {
                chatId: chatId,
                limit: limit,
                page: page
            })
            .then((res) => {
                return res.data;
            });
    },
    addMessage(chatId, text) {
        return api
            .post(`/chat/message`, {
                chatId: chatId,
                text: text
            })
            .then((res) => {
                return res.data;
            });
    },
    getMentorChat(id) {
        return api
            .post(`/chat/mentor/get`, {
                id: id
            })
            .then((res) => {
                return res.data;
            });
    },
    getChat(chatId) {
        return api
            .post(`/chat/get`, {
                chatId: chatId
            })
            .then((res) => {
                return res.data;
            });
    },
    getMessageCount(chatId) {
        return api
            .post(`/chat/message/count`, {
                chatId: chatId
            })
            .then((res) => {
                return res.data;
            });
    }
};

export default chatHandler;
