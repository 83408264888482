import api from './api';

const candidateSkillHandler = {
    addSkill(skillId) {
        return api
            .post('/candidateSkill', {
                skillId: skillId
            })
            .then((res) => {
                return res.data;
            });
    },
    deleteSkill(skill) {
        return api.delete(`/candidateSkill/${skill.id}`).then((res) => {
            return res.data;
        });
    },

    addMentorSkill(skillId) {
        return api
            .post('/candidateSkill/mentor', {
                skillId: skillId
            })
            .then((res) => {
                return res.data;
            });
    },
    deleteMentorSkill(skill) {
        return api.delete(`/candidateSkill/mentor/${skill.id}`).then((res) => {
            return res.data;
        });
    }
};

export default candidateSkillHandler;
