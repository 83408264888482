import React, { useState, useEffect } from 'react';
import { Badge } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import chatHandler from 'api/chat';
import { useDispatch, useSelector } from 'react-redux';
import { SET_BADGE } from 'store/actions';

const ChatWithBadge = () => {
    const dispatch = useDispatch();

    const badge = useSelector((state) => {
        return state.customization.badge;
    });

    useEffect(() => {
        const fetchData = async () => {
            const { messageCount } = await chatHandler.getMessageCount(17);
            dispatch({ type: SET_BADGE, badge: messageCount });
        };

        if (!badge && badge !== 0) {
            fetchData();
        }
    }, []);

    return (
        <Badge badgeContent={badge} color="secondary">
            <ChatIcon color="action" sx={{ color: '#616161' }} />
        </Badge>
    );
};

export default ChatWithBadge;
