import { Avatar, Box, Button, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CheckCircleOutlined as CheckCircleOutlinedIcon } from '../../../icons/check-circle-outlined';
import { Users as UsersIcon } from '../../../icons/users';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Groups2Icon from '@mui/icons-material/Groups2';

export const HomeHero = (props) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: 'background.paper',
                pt: 6
            }}
            {...props}
        >
            <Container
                maxWidth="md"
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography color="primary" variant="overline">
                    Coshaco
                </Typography>
                <Typography align="center" variant="h1">
                    Un recrutement,<br></br> simple, facile et efficace
                </Typography>
                <Typography align="center" color="textSecondary" variant="subtitle1" sx={{ py: 3 }}>
                    Créez votre profil, ajoutez un salaire, des préférences et répondez aux offres Oui ou Non. C'est aussi simple que cela.
                </Typography>
                <Box
                    sx={{
                        alignItems: {
                            sm: 'center',
                            xs: 'flex-start'
                        },
                        display: 'flex',
                        flexDirection: {
                            sm: 'row',
                            xs: 'column'
                        },
                        py: 0,
                        m: -1,
                        '& > *': {
                            m: 1
                        }
                    }}
                >
                    <Typography color="textSecondary" variant="caption">
                        Disponible pour des rôles:
                    </Typography>
                    {['Tech', 'Product Management', 'Design'].map((item) => (
                        <Box
                            key={item}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                m: 2
                            }}
                        >
                            <CheckCircleOutlinedIcon color="success" sx={{ mr: 1 }} />
                            <Typography variant="subtitle2">{item}</Typography>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        mx: -1,
                        mt: 2,
                        mb: 6,
                        '& > a': {
                            m: 1
                        }
                    }}
                >
                    <Button component="a" size="large" variant="contained">
                        Demander une démo
                    </Button>
                </Box>
            </Container>
            <Box
                sx={{
                    maxWidth: 980,
                    width: '100%',
                    mx: 'auto'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative',
                        pt: 'calc(600 / 980 * 100%)',
                        '& img': {
                            height: 'auto',
                            position: 'absolute',
                            top: 0,
                            width: '60%'
                        }
                    }}
                >
                    <img alt="" src={`/static/home/KanbanCard_${theme.palette.mode}.png`} />
                </Box>
            </Box>
            <Box sx={{ py: 8, mt: 10 }}>
                <Container
                    maxWidth="md"
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: {
                            xs: 'column',
                            md: 'row'
                        },
                        px: 3
                    }}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Avatar
                            sx={{
                                backgroundColor: 'primary.main',
                                height: 40,
                                mb: 2,
                                width: 40
                            }}
                            variant="rounded"
                        >
                            <UsersIcon fontSize="small" />
                        </Avatar>
                        <Typography
                            sx={{
                                color: 'textPrimary',
                                textAlign: 'center'
                            }}
                            variant="h4"
                        >
                            Profils tech
                        </Typography>
                        <Typography sx={{ color: 'textPrimary' }} variant="overline">
                            De la reconversion à sénior
                        </Typography>
                    </Box>
                    {/* <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Avatar
              sx={{
                backgroundColor: "secondary.main",
                height: 40,
                mb: 2,
                width: 40,
              }}
              variant="rounded"
            >
              <StarIcon fontSize="small" />
            </Avatar>
            <Typography
              sx={{
                color: "textPrimary",
                textAlign: "center",
              }}
              variant="h4"
            >
              4.9/5
            </Typography>
            <Typography sx={{ color: "textPrimary" }} variant="overline">
              CUSTOMER Rating
            </Typography>
          </Box> */}
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Avatar
                            sx={{
                                backgroundColor: 'warning.main',
                                height: 40,
                                mb: 2,
                                width: 40
                            }}
                            variant="rounded"
                        >
                            <AccountTreeIcon fontSize="small" />
                        </Avatar>
                        <Typography
                            sx={{
                                color: 'textPrimary',
                                textAlign: 'center'
                            }}
                            variant="h4"
                        >
                            Algorithme de matching
                        </Typography>
                        <Typography sx={{ color: 'textPrimary' }} variant="overline">
                            Système intélligent
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Avatar
                            sx={{
                                backgroundColor: 'info.main',
                                height: 40,
                                mb: 2,
                                width: 40
                            }}
                            variant="rounded"
                        >
                            <Groups2Icon fontSize="small" />
                        </Avatar>
                        <Typography
                            sx={{
                                color: 'textPrimary',
                                textAlign: 'center'
                            }}
                            variant="h4"
                        >
                            Accompagnement
                        </Typography>
                        <Typography sx={{ color: 'textPrimary' }} variant="overline">
                            Aide sur la rétention
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};
